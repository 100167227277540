import React, { useState, useLayoutEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../components/layoutNoStripe"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../styledComponents/section"
import SignupNewsletterForm  from "../components/formNewsletterSignup"
import { StyledSignUpForm } from "../styledComponents/signUpForm"
import Testimonials from "../components/testimonials"
import { StyledAboutKarina } from "../styledComponents/aboutKarina"
import EventList from "../components/EventList"
import {
  StyledBlogSectionWrapper
} from "../styledComponents/blogArchive"
import ProductList from "../components/ProductList"
import {
  StyledViewAllEventsButtonWrapper
} from "../styledComponents/eventArchive"
import { StyledLinkButton } from "../styledComponents/button"
import HeroBanner from "../components/HeroBanner"
import { HeroText, HeroHeader, HeroDescription } from "../styledComponents/heroBanner"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const ThankYou = ({ data, location, createCookie }) => {  
  const [param, setParam] = useState(false)
  
  let posts = data.allWpPost.nodes
  const orderPostsByPinned = () =>
    posts.sort((a,b) => {
      var postA = a
      var postB = b
      if (postA.isSticky === true) {
        return new Date(postA.date) - new Date(postB.date);
      }
      if (postA === false) {
        return 1
      }

      //posts are the same
      return 0
    })
  posts = orderPostsByPinned()

  let products = data.allWpProduct.edges
  const image = {
    imageData: data.aboutKarina.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.aboutKarina.featuredImage?.node?.alt || ``,
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const coachingParam = urlParams.get('coaching') 
      if(coachingParam){
        setParam('coaching')
      }
      
      return
    }
  }, [])

    return (
    <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />x
      </Helmet>
      <LayoutNoStripe location={location} isHomePage>
        <HeroBanner image="/images/hands-heart-world.jpg" type="homepage">
            <HeroText>
              <HeroHeader>
                <strong>Thank you</strong>
              </HeroHeader>
              <HeroDescription>Thanks for submitting your information. We will contact you soon{!param && ' to tell you more about Quantum-Touch'}.</HeroDescription>              }
            </HeroText>
        </HeroBanner>

        <StyledSignUpForm>
          <SignupNewsletterForm createCookie={createCookie}/>
        </StyledSignUpForm>

        <Testimonials 
          data={data} 
          showTestimonialsSectionHeader={true} 
          showMoreTestimonialsButton={true} 
          showMoreTestimonialsButtonLink="/testimonials" 
          showMoreTestimonialsButtonText="Read more testimonials"
        />
        <StyledBlogSectionWrapper pageType="home">
          <Section >
            <Heading level={1}><Link to="/shop">Mindfulness Meditations</Link></Heading>
            <ProductList posts={products} />
            <StyledViewAllEventsButtonWrapper><StyledLinkButton to="/shop">All meditations</StyledLinkButton></StyledViewAllEventsButtonWrapper>
          </Section>
        </StyledBlogSectionWrapper>

        <StyledAboutKarina>
        {image && image.imageData && (
          <figure>
            <GatsbyImage image={image.imageData} alt={image.alt} transformoptions={{ layout: "FULL_WIDTH" }} />
          </figure>
        )}
          <div>
            <h2>Karina Grant</h2>
            {parse(data.aboutKarina.aboutKarina.aboutKarina)}
            <StyledViewAllEventsButtonWrapper><StyledLinkButton to="/about">Read more</StyledLinkButton></StyledViewAllEventsButtonWrapper>
          </div>
        </StyledAboutKarina>

      </LayoutNoStripe>
      </>
    )
}

export default ThankYou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
     

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
